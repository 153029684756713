<template>
  <div class="auth">
    <Header :title="'重置交易密码'" :back="true"/>
    <div class="box-items">
      <div class="utitle">为确保您的资金账户安全，修改交易密码前需要先验证您的身份信息，请输入已认证的身份证号！</div>
      <el-input
        class="form-items"
        @focus="tips=''"
        placeholder="请输入身份证号码"
        v-model="idcard"
        prefix-icon="el-icon-postcard"
        clearable>
      </el-input>
       <el-input
        class="form-items"
        placeholder="新6位数字交易密码"
        v-model="pwd"
        @focus="tips=''"
        prefix-icon="el-icon-lock"
        type="password"
        clearable>
      </el-input>
      <div v-if="tips" class="error-tips"><i class="el-icon-error"></i> {{tips}}</div>
      <div class="form-items"><button class="wid-btn" style="width:96%" @click="handleAuth">确认重置</button></div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      pwd:'',
      idcard:''
    }
  },
  methods:{
    handleAuth(){
      if (this.pwd === ''||this.idcard === '') {
        this.tips = "身份证号码和交易密码不能为空"
        return false
      }else if(this.checkCard(this.idcard)==false){
        this.tips = "身份证号码格式不正确"
        return false
      }else if(!(/^[0-9]{6}$/.test(this.pwd)) ){
        this.tips = "交易密码为6位的数字"
        return false
      }
      let params = {
        type:'tradepassword',
        tradepassword:this.pwd,
        idcard:this.idcard
      }
      this.$store
        .dispatch('auth/updateUser',params)
        .then(() => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .box-items{
    margin-top:2.5%
  }
  .form-items{
    margin: 1rem auto 0px;
  }
  .checkbox-box{
    font-size: .8rem;
    color: #464646;
    margin-top:3px;
   
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
    margin-bottom:10px;
    margin-left:10%;
    margin-right:10%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.utitle{
  color: #464646;
  // line-height: 2;
  font-size: .9rem;
}
</style>