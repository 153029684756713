var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c("Header", { attrs: { title: "重置交易密码", back: true } }),
      _c(
        "div",
        { staticClass: "box-items" },
        [
          _c("div", { staticClass: "utitle" }, [
            _vm._v(
              "为确保您的资金账户安全，修改交易密码前需要先验证您的身份信息，请输入已认证的身份证号！"
            ),
          ]),
          _c("el-input", {
            staticClass: "form-items",
            attrs: {
              placeholder: "请输入身份证号码",
              "prefix-icon": "el-icon-postcard",
              clearable: "",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.idcard,
              callback: function ($$v) {
                _vm.idcard = $$v
              },
              expression: "idcard",
            },
          }),
          _c("el-input", {
            staticClass: "form-items",
            attrs: {
              placeholder: "新6位数字交易密码",
              "prefix-icon": "el-icon-lock",
              type: "password",
              clearable: "",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.pwd,
              callback: function ($$v) {
                _vm.pwd = $$v
              },
              expression: "pwd",
            },
          }),
          _vm.tips
            ? _c("div", { staticClass: "error-tips" }, [
                _c("i", { staticClass: "el-icon-error" }),
                _vm._v(" " + _vm._s(_vm.tips)),
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-items" }, [
            _c(
              "button",
              {
                staticClass: "wid-btn",
                staticStyle: { width: "96%" },
                on: { click: _vm.handleAuth },
              },
              [_vm._v("确认重置")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }